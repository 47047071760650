import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {AuditLog} from '../models/audit-log.interface';
import {HttpClient} from '@angular/common/http';
import {AuditSearchResult} from '../models/audit-search-result.interface';

@Injectable({
	providedIn: 'root'
})
export class AuditLogService {
	constructor(private http: HttpClient) {}
	baseUrl: string = `${environment.url}/core/audit-log`;

	findManyByPkId(tableName: string, tablePkId: string): Observable<AuditLog[]> {
		return this.http.get<AuditLog[]>(`${this.baseUrl}/pk?tableName=${tableName}&tablePkId=${tablePkId}`);
	}
	findManyByString(tableName: string, searchTerm: string): Observable<AuditSearchResult[]> {
		return this.http.get<AuditSearchResult[]>(`${this.baseUrl}/search?tableName=${tableName}&searchTerm=${searchTerm}`);
	}
}
