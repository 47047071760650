import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from 'src/app/core/services/user.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {UserTypeEnum} from '../../../core/enums/user-type.enum';
import {Role} from '../../../core/models/role.interface';
import {ValidatorRegexEnum} from '../../../core/enums/validator-regex.enum';

@Component({
	selector: 'app-create-user',
	templateUrl: './create-user.component.html',
	styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
	userTypeOptions: string[] = ['external', 'internal', 'admin'];
	form: FormGroup;
	roles: Role[] = [];
	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService,
		public dialogRef: MatDialogRef<CreateUserComponent>,
		private snackbar: MatSnackBar
	) {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required, Validators.pattern(ValidatorRegexEnum.EMAIL), Validators.maxLength(254)]],
			firstName: ['', [Validators.required, Validators.maxLength(100)]],
			lastName: ['', [Validators.required, Validators.maxLength(100)]],
			phone: ['', Validators.maxLength(40)],
			phoneExt: ['', Validators.maxLength(10)],
			description: ['', Validators.maxLength(256)],
			roleId: ['', Validators.required],
			type: [UserTypeEnum.INTERNAL, Validators.required]
		});
	}
	ngOnInit() {}
	createUser() {
		this.userService.create(this.form.getRawValue()).subscribe(
			(response) => {
				this.dialogRef.close();
				this.snackbar.open('User Successfully Created!', SnackbarActionEnum.SUCCESS);
				this.userService.findAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}
}
