<div id="container" [ngClass]="{'keys': keys.length, 'first-column': displayAllData, 'bg-white': !keys.length && !displayAllData && data}">
	<p class="font-semibold datebar">
		{{ data ? data.logTs : 'Date / Updated User'
		}}<span *ngIf="data" [matTooltip]="updatedUser ? updatedUser : 'Updated user not found'"
			>&nbsp;-&nbsp;{{ this.updatedUser ? this.updatedUser.substring(0, 5) + '...' : 'N/A' }}</span
		>
	</p>
	<p *ngFor="let key of keys">{{ key }}</p>
	<p *ngFor="let column of dataArray; index as i">
		{{ (displayAllData ? column[1] : jsonDifferences.includes(column[0])) ? column[1] : '&nbsp;' }}
	</p>
</div>
