import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, group, query, state, style, transition, trigger} from '@angular/animations';
import {MatSidenav} from '@angular/material/sidenav';
//import {navData, navItem} from './nav-options';
import {Router} from '@angular/router';
import {navData, navItem} from '../main-toolbar/nav-options';

@Component({
	selector: 'app-side-nav',
	animations: [
		trigger('openClose', [
			state(
				'open',
				style({
					width: '14vw'
				})
			),
			state(
				'closed',
				style({
					width: '5.5vw'
				})
			),
			transition('void => *', [animate('0.0s')]),
			transition('open => closed', [
				group([
					animate(200),
					query('.animated-sidebar', [
						animate(200),
						style({
							transform: 'translateX(4vw)'
						})
					])
				])
			]),
			transition('closed => open', [
				group([
					animate(200),
					query('.animated-sidebar', [
						animate(200),
						style({
							transform: 'translateX(11.6vw)'
						})
					])
				])
			])
		]),
		trigger('buttonMoved', [
			state(
				'open',
				style({
					transform: 'translateX(11.6vw)'
				})
			),
			state(
				'closed',
				style({
					transform: 'translateX(4vw)'
				})
			)
		])
	],
	templateUrl: './side-nav.component.html',
	styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
	@ViewChild('sidenav', {static: false}) sidenav: MatSidenav | undefined;
	navOptions: navItem[];
	autosize: boolean = true;
	isExpanded: boolean = true;
	group: any = localStorage.getItem('group');
	constructor(private router: Router) {}

	ngOnInit(): void {
		this.navOptions = navData;
	}

	toggleSidenav() {
		this.isExpanded = !this.isExpanded;
		this.autosize = false;
	}

	navigate(item: navItem): void {
		this.router.navigate([item.routerLink], item.extras);
	}
}
