import {Component, OnInit} from '@angular/core';
import {AuditLogService} from '../../services/audit-log.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuditLog} from '../../models/audit-log.interface';
import {AuditControlService} from '../../services/audit-control.service';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs';
import {AuditSearchResult} from '../../models/audit-search-result.interface';
import {format} from 'date-fns';
import {AuditControl} from '../../models/audit-control.interface';

@Component({
	selector: 'app-audit-log',
	templateUrl: './audit-log.component.html',
	styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {
	//TODO: add user and function that currently arent being logged
	filterForm: FormGroup;
	keys: string[] = [];
	masterKeys: string[];
	masterDisplayColumns: AuditLog[];
	displayColumns: AuditLog[] = [];
	tableNames: AuditControl[] = [];
	loading: boolean = false;
	autocompleteOptionsLoading: boolean = false;
	autocompleteOptions: AuditSearchResult[] = [];
	selectedTablePk: string;
	showTable: boolean = false;
	showOnlyLoggedTables: boolean = true;
	showOnlyChanges: boolean = true;
	allTableNames: AuditControl[];
	SEARCH_MIN_CHARACTERS: number = 3;

	constructor(private auditLogService: AuditLogService, private auditControlService: AuditControlService, private fb: FormBuilder) {
		this.filterForm = this.fb.group({
			tableName: ['', Validators.required],
			tablePkId: [{value: '', disabled: true}, Validators.required],
			searchValue: [{value: '', disabled: true}, Validators.required]
		});
	}

	ngOnInit() {
		this.filterForm.controls['tableName'].valueChanges.subscribe((tableNameValue) => {
			if (tableNameValue) {
				//to prevent repeatedly trying to enable()
				if (this.filterForm.controls['searchValue'].disabled) {
					this.filterForm.controls['searchValue'].enable();
				}
			}
		});
		this.filterForm.controls['searchValue'].valueChanges
			.pipe(
				debounceTime(700),
				distinctUntilChanged(), // Only emit if the value has changed
				switchMap((value: string) => {
					this.autocompleteOptionsLoading = true;
					if (this.filterForm.valid && value?.length >= this.SEARCH_MIN_CHARACTERS) {
						this.autocompleteOptions = [];
						// Send the value to the backend and receive autocomplete options
						return this.auditLogService.findManyByString(this.filterForm.get('tableName')?.value, value);
					} else {
						this.autocompleteOptionsLoading = false;
						this.autocompleteOptions = [];
						return [];
					}
				})
			)
			.subscribe((options) => {
				this.autocompleteOptions = options;
				this.autocompleteOptionsLoading = false;
			});

		this.auditControlService.findAll().subscribe((response) => {
			this.allTableNames = response;
			this.tableNames = this.allTableNames.filter((table: AuditControl) => table.auditTriggerActive === 'Y');
		});
	}

	onShowLoggedCheckboxToggle() {
		this.showOnlyLoggedTables = !this.showOnlyLoggedTables;
		if (this.showOnlyLoggedTables) {
			this.tableNames = this.allTableNames.filter((table: AuditControl) => table.auditTriggerActive === 'Y');
		} else {
			this.tableNames = this.allTableNames;
		}
	}

	onShowChangesCheckboxToggle() {
		this.showOnlyChanges = !this.showOnlyChanges;
		if (this.showOnlyChanges) {
			this.handleShowChangesOnly();
		} else {
			this.keys = this.masterKeys;
			let tempArray: AuditLog[] = [];
			this.masterDisplayColumns.forEach((each) => tempArray.push({...each}));
			this.displayColumns = tempArray;
		}
	}

	findLifeLongChanges(keyName: string, valueToCheck: any): boolean {
		let containsDifferentValues: boolean = false;

		for (const column of this.displayColumns) {
			const afterObj: Record<string, any> = JSON.parse(column.afterRowData);
			for (const key in afterObj) {
				if (key === keyName && afterObj[key] !== valueToCheck) {
					containsDifferentValues = true;
				}
			}
		}
		return containsDifferentValues;
	}

	handleShowChangesOnly(): void {
		//make deep copy of displayColumns to serve as master
		this.masterDisplayColumns = this.displayColumns.map((obj) => JSON.parse(JSON.stringify(obj)));

		const beforeObj: Record<string, any> = JSON.parse(this.displayColumns[0].beforeRowData ?? '{}');

		let rowsToRemove: [string, any][] = Object.entries(beforeObj);
		// It is a row to remove if the before value is falsy, or it's value has never changed in its' history
		rowsToRemove = rowsToRemove.filter((each: [string, any]) => !(each[1] && this.findLifeLongChanges(each[0], each[1])));

		const masterDisplayColumns: AuditLog[] = [];

		//remove row from displayColumns
		this.displayColumns.forEach((displayColumn: AuditLog) => {
			const tempObject = JSON.parse(displayColumn.afterRowData);
			rowsToRemove.forEach((column: [string, any]) => {
				delete tempObject[column[0]];
			});
			displayColumn.afterRowData = JSON.stringify(tempObject);
			//reassign column reference to trigger changeDetection
			masterDisplayColumns.push({...displayColumn});
		});
		this.displayColumns = masterDisplayColumns;

		//remove keys from display
		this.keys = this.keys.filter((key) => !rowsToRemove.find((column) => column[0] === key));
	}

	clearSearchOptions() {
		this.autocompleteOptions = [];
		this.selectedTablePk = '';
		this.filterForm.get('searchValue')?.setValue('');
	}

	get searchValue(): string {
		return this.filterForm.get('searchValue')?.value;
	}

	onTablePkSelection(optionData: any) {
		this.selectedTablePk = optionData.tablePkId;
	}

	displayFn(option: AuditSearchResult): string {
		return option.tablePkId ? '"id": ' + String(option.tablePkId) : '';
	}

	onSubmit() {
		const {tableName} = this.filterForm.value;
		this.loading = true;
		this.showTable = true;

		this.auditLogService.findManyByPkId(tableName, this.selectedTablePk).subscribe((response: AuditLog[]) => {
			const tempKeys = Object.keys(JSON.parse(response[0].afterRowData));
			//remove updatedTs, createdTs, and updatedUser
			let propertiesToRemove = ['updated_ts', 'created_ts', 'updated_user'];
			this.masterKeys = tempKeys.filter((each: string) => {
				return !propertiesToRemove.includes(each);
			});
			this.keys = this.masterKeys;
			const theTrueResponse: AuditLog[] = [this.correctDates(response[0])];

			response.slice(1).forEach((log: AuditLog) => {
				let returnBoolean = this.compareJSONStrings(log.beforeRowData, log.afterRowData).length;
				if (returnBoolean) {
					theTrueResponse.push(this.correctDates(log));
				}
			});

			this.displayColumns = theTrueResponse;
			if (this.showOnlyChanges) this.handleShowChangesOnly();
			this.loading = false;
		});
	}

	correctDates(log: AuditLog): AuditLog {
		const date = new Date(log.logTs);
		const utcDate: Date = new Date(
			Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
		);
		log.logTs = `${format(utcDate, 'MMM d, y h:mma')}`;
		return log;
	}

	compareJSONStrings(beforeJsonStr: string | null, afterJsonStr: string | null): string[] {
		// Return empty array if either JSON string is null
		if (beforeJsonStr === null || afterJsonStr === null) {
			return [];
		}

		const beforeObj: Record<string, any> = JSON.parse(beforeJsonStr);
		const afterObj: Record<string, any> = JSON.parse(afterJsonStr);
		delete beforeObj['updated_ts'];
		delete beforeObj['created_ts'];
		delete beforeObj['updated_user'];
		delete afterObj['updated_ts'];
		delete afterObj['created_ts'];
		delete afterObj['updated_user'];

		const differentKeys: string[] = [];

		for (const key in beforeObj) {
			if (beforeObj.hasOwnProperty(key) && afterObj.hasOwnProperty(key)) {
				if (beforeObj[key] !== afterObj[key]) {
					differentKeys.push(key);
				}
			} else {
				// Key doesn't exist in one of the objects, consider it different.
				differentKeys.push(key);
			}
		}

		// Check for keys that exist in afterObj but not in beforeObj.
		for (const key in afterObj) {
			if (afterObj.hasOwnProperty(key) && !beforeObj.hasOwnProperty(key)) {
				differentKeys.push(key);
			}
		}

		return differentKeys.length > 0 ? differentKeys : [];
	}
}
