<div class="p-2 flex justify-center space-x-2" [formGroup]="codeOptionForm">
	<mat-form-field subscriptSizing="dynamic">
		<mat-label>Select Code Type</mat-label>
		<mat-select formControlName="code" (selectionChange)="onCodeSelected($event.value.codeType)">
			<mat-option *ngFor="let option of codeOptions" [value]="option">
				{{ option.codeDescription }}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="user-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name, email or phone number."
			matInput
			[disabled]="!codeSelected"
			#searchInput
		/>
	</mat-form-field>
	<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openCodeDialog(false, {}, true)">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 h-full border-gray-700 border-2 rounded overflow-auto m-auto" *ngIf="codeSelected">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="codeSelected">
		<!--Description Column-->
		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by description">Description</th>
			<td mat-cell *matCellDef="let code" class="{{ code.code === '#' ? 'font-bold' : '' }}">
				{{ code.codeDescription }}
			</td>
		</ng-container>
		<!--Code Column-->
		<ng-container matColumnDef="code">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by code">Code</th>
			<td mat-cell *matCellDef="let code" class="{{ code.code === '#' ? 'font-bold' : '' }}">
				{{ code.code }}
			</td>
		</ng-container>
		<!--Type Column-->
		<ng-container matColumnDef="type">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">Type</th>
			<td mat-cell *matCellDef="let code" class="{{ code.code === '#' ? 'font-bold' : '' }}">
				{{ code.codeType }}
			</td>
		</ng-container>
		<!--Actions Column-->
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let code" class="flex justify-end">
				<button mat-icon-button (click)="openCodeDialog(true, code)"><mat-icon>edit</mat-icon></button>
				<button mat-icon-button (click)="deleteCode(code)" *ngIf="code.code !== '#'"><mat-icon>delete</mat-icon></button>
				<button mat-icon-button (click)="openCodeDialog(false, code)" *ngIf="code.code === '#'"><mat-icon>add</mat-icon></button>
			</td>
		</ng-container>
		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let broker" [attr.colspan]="columnsToDisplay.length"></td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let code; let i = dataIndex; columns: columnsToDisplay"
			class="code-row"
			(click)="code.expanded = !code.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!codeSelected">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
</div>
