import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Code} from '../../../core/models/code.interface';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: 'app-codes-dialog',
	templateUrl: './codes-dialog.component.html',
	styleUrls: ['./codes-dialog.component.scss']
})
export class CodesDialogComponent {
	editMode: boolean;
	createCodeType: boolean = false;
	form: FormGroup;
	code: Code;

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<CodesDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackbar: MatSnackBar
	) {
		this.editMode = data.editMode;
		this.createCodeType = data.createCodeType;
		this.code = data.code;

		this.form = this.fb.group({
			codeDescription: new FormControl(this.code.codeDescription ?? '', Validators.required),
			code: new FormControl(this.code.code ?? '', Validators.required),
			codeType: new FormControl(this.code.codeType ?? '', Validators.required)
		});

		if (this.createCodeType) {
			this.form.get('code')?.disable();
		} else {
			this.form.get('codeType')?.disable();
			if (this.code.code === '#') {
				this.form.get('code')?.disable();
			}
		}
	}

	saveCode(): void {
		if (this.form.valid) {
			this.code.codeDescription = this.form.get('codeDescription')?.value;
			this.code.code = this.form.get('code')?.value;
			this.code.codeType = this.form.get('codeType')?.value;

			this.dialogRef.close(this.code);
		} else {
			this.snackbar.open('Please fill out required fields before submitting.', SnackbarActionEnum.ALERT);
		}
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
