import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuditControl} from '../models/audit-control.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AuditControlService {
	baseUrl: string = `${environment.url}/core/audit-control`;
	constructor(private http: HttpClient) {}

	findAll(): Observable<AuditControl[]> {
		return this.http.get<AuditControl[]>(`${this.baseUrl}`);
	}
}
