import {Component, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Code} from '../../../core/models/code.interface';
import {CodeService} from '../../../core/services/code.service';
import {CodesDialogComponent} from '../codes-dialog/codes-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-codes-list',
	templateUrl: './codes-list.component.html',
	styleUrls: ['./codes-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class CodesListComponent {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	columnsToDisplay: string[] = ['description', 'code', 'type', 'actions'];

	isLoadingCodes: boolean = true;
	codeSelected: boolean = false;
	codes: Code[] = [];
	codeOptions: Code[];
	codeOptionForm: FormGroup = new FormGroup({
		code: new FormControl(false)
	});

	constructor(private codeService: CodeService, private dialog: MatDialog, private snackbar: MatSnackBar) {}

	ngOnInit(): void {
		this.dataSource = new MatTableDataSource();
		this.codeService.findAll();
		this.codeService.codeSubject.subscribe((response: Code[]): void => {
			this.codes = response;
			this.codeOptions = response.filter((value: Code) => value.code === '#');
			// Store the expanded state of each row before updating the data source
			const expandedStates: boolean[] = [];
			for (let i = 0; i < this.dataSource.data.length; i++) {
				expandedStates[i] = this.dataSource.data[i].expanded;
			}
			// Update the data source with new data
			for (const updatedRow of response) {
				const index = this.dataSource.data.findIndex((row) => row.id === updatedRow.id);
				if (index !== -1) {
					// Preserve the expanded state of the row before updating it
					updatedRow.expanded = this.dataSource.data[index].expanded;
					this.dataSource.data[index] = updatedRow;
				} else {
					// Add the new row to the data source
					this.dataSource.data.push(updatedRow);
				}
			}
			// Restore the expanded state of each row after updating the data source
			for (let i = 0; i < this.dataSource.data.length; i++) {
				this.dataSource.data[i].expanded = expandedStates[i];
			}
			// Refresh the data source to update the table
			this.dataSource.data = [...this.dataSource.data];
			this.isLoadingCodes = false;
		});
	}

	onCodeSelected(codeType: string): void {
		this.dataSource.data = this.codes.filter((code: Code): boolean => code.codeType === codeType);
		this.codeSelected = true;
	}

	applyFilter(event: Event): void {
		const filterValue: string = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCodeDialog(editMode: boolean, code: Code, newCodeType?: boolean): void {
		let newCode: Code = {};

		if (!editMode) {
			newCode = {
				codeDescription: '',
				code: ''
			};

			if (newCodeType) {
				newCode.code = '#';
			} else {
				newCode.codeType = code.codeType;
			}
		}

		const dialogRef: MatDialogRef<CodesDialogComponent> = this.dialog.open(CodesDialogComponent, {
			data: {
				editMode: editMode,
				code: editMode ? code : newCode,
				createCodeType: newCodeType
			}
		});

		dialogRef.afterClosed().subscribe((result: Code): void => {
			if (result) {
				editMode ? this.updateCode(result) : this.createCode(result, newCodeType);
			}
		});
	}

	updateCode(code: Code): void {
		this.codeService.update(code.id!, code).subscribe({
			next: (): void => {
				this.snackbar.open('Successfully updated code.', SnackbarActionEnum.SUCCESS);
			},
			error: (err): void => {
				console.error(err);
				this.snackbar.open('Failed to update code.', SnackbarActionEnum.ERROR);
			}
		});
	}

	createCode(code: Code, newCodeType?: boolean): void {
		this.codeService.create(code).subscribe({
			next: (result: Code): void => {
				this.codes.push(result);
				if (newCodeType) {
					this.codeOptions.push(result);
				}
				this.onCodeSelected(result.codeType!);
				this.snackbar.open('Successfully created code.', SnackbarActionEnum.SUCCESS);
			},
			error: (err): void => {
				console.error(err);
				this.snackbar.open('Failed to create new code', SnackbarActionEnum.ERROR);
			}
		});
	}

	deleteCode(code: Code): void {
		this.codeService.delete(code.id!).subscribe({
			next: (): void => {
				const index: number = this.codes.findIndex((foundCode: Code) => foundCode.id === code.id);
				this.codes.splice(index, 1);
				this.onCodeSelected(code.codeType!);
				this.snackbar.open('Successfully deleted code.', SnackbarActionEnum.SUCCESS);
			},
			error: (err): void => {
				console.error(err);
				this.snackbar.open('Failed to delete code.', SnackbarActionEnum.ERROR);
			}
		});
	}
}
