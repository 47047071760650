<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
	<p>{{ data.message }}</p>
	<p [formGroup]="codeForm">
		<mat-form-field appearance="outline" class="mr-2">
			<mat-label>{{ data.codeLabel }}</mat-label>
			<mat-select formControlName="code">
				<mat-option [value]="code.code" *ngFor="let code of codes">{{ code.codeDescription }}</mat-option>
			</mat-select>
		</mat-form-field>
	</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button mat-dialog-close>Cancel</button>
	<button mat-raised-button color="primary" (click)="save()" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
