<mat-card class="flex flex-col p-4">
	<mat-card-title>
		{{ editMode ? 'Update Code' : 'Create New Code' }}
	</mat-card-title>
	<div class="flex space-x-2 mt-2" [formGroup]="form">
		<mat-form-field>
			<mat-label>Description</mat-label>
			<input formControlName="codeDescription" matInput />
			<mat-error *ngIf="this.form.get('codeDescription')?.touched && this.form.get('codeDescription')?.invalid">
				This is a required field
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Code</mat-label>
			<input formControlName="code" matInput />
			<mat-error *ngIf="this.form.get('code')?.touched && this.form.get('code')?.invalid"> This is a required field </mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Code Type</mat-label>
			<input formControlName="codeType" matInput />
			<mat-error *ngIf="this.form.get('codeType')?.touched && this.form.get('codeType')?.invalid"> This is a required field </mat-error>
		</mat-form-field>
	</div>
	<div class="flex justify-end space-x-2">
		<button mat-flat-button (click)="closeDialog()">CANCEL</button>
		<button mat-flat-button color="primary" (click)="saveCode()">{{ editMode ? 'UPDATE CODE' : 'ADD NEW CODE' }}</button>
	</div>
</mat-card>
