import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationDialogData} from '../confirmation-dialog/confirmation-dialog.component';
import {CodeService} from '../../../core/services/code.service';
import {CodeTypeEnum} from '../../../core/enums/code-type.enum';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {Code} from '../../../core/models/code.interface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface CodeConfirmationDialogData {
	title: string;
	message: string;
	code: string;
	codeLabel: string;
}

export interface CodeConfirmationDialogResponse {
	code: string;
	success: boolean;
}

@Component({
	selector: 'app-code-confirmation-dialog',
	templateUrl: './code-confirmation-dialog.component.html',
	styleUrls: ['./code-confirmation-dialog.component.scss']
})
export class CodeConfirmationDialogComponent implements OnInit {
	codeForm = new FormGroup({
		code: new FormControl('', [Validators.required])
	});

	codes: Code[] = [];

	loading = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: CodeConfirmationDialogData,
		private dialogRef: MatDialogRef<CodeConfirmationDialogComponent>,
		private codeService: CodeService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar
	) {
		if (!data.title) {
			data.title = 'Confirm';
		}
		if (!data.message) {
			data.message = 'Are you sure?';
		}
		if (!data.codeLabel) {
			data.codeLabel = 'Code';
		}
	}

	ngOnInit(): void {
		this.loading = true;
		this.codeService.findByType(this.data.code).subscribe({
			next: (codes): void => {
				this.codes = codes;
				this.loading = false;
			},
			error: (err: any): void => {
				this._snackBar.open('Error loading Codes' + err.error.message, SnackbarActionEnum.ERROR);
			}
		});
	}

	save() {
		this.dialogRef.close({success: true, code: this.codeForm.controls.code.value});
	}
}
