import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AuditLog} from '../../../models/audit-log.interface';

@Component({
	selector: 'app-audit-log-display',
	templateUrl: './audit-log-display.component.html',
	styleUrls: ['./audit-log-display.component.scss']
})
export class AuditLogDisplayComponent implements OnChanges {
	@Input() keys: string[] = [];
	@Input() data: AuditLog;
	@Input() displayAllData: boolean = false;
	jsonDifferences: string[];
	dataArray: any[] = [];
	updatedUser: string;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['data']) {
			if (this.data) {
				this.dataArray = Object.entries(JSON.parse(this.data.afterRowData));

				const updatedUserInfo = this.dataArray.find((each) => each[0] === 'updated_user');
				this.updatedUser = updatedUserInfo ? updatedUserInfo[1] : '';

				//remove updatedTs, createdTs, and updatedUser
				let propertiesToRemove = ['updated_ts', 'created_ts', 'updated_user'];
				this.dataArray = this.dataArray.filter((each: string[]) => {
					return !propertiesToRemove.includes(each[0]);
				});
				this.jsonDifferences = this.compareJSONStrings(this.data.beforeRowData, this.data.afterRowData);
			}
		}
	}

	compareJSONStrings(beforeJsonStr: string | null, afterJsonStr: string | null): string[] {
		// Return empty array if either JSON string is null
		if (beforeJsonStr === null || afterJsonStr === null) {
			return [];
		}

		const beforeObj: Record<string, any> = JSON.parse(beforeJsonStr);
		const afterObj: Record<string, any> = JSON.parse(afterJsonStr);

		const differentKeys: string[] = [];

		for (const key in beforeObj) {
			if (beforeObj.hasOwnProperty(key) && afterObj.hasOwnProperty(key)) {
				if (beforeObj[key] !== afterObj[key]) {
					differentKeys.push(key);
				}
			} else {
				// Key doesn't exist in one of the objects, consider it different.
				differentKeys.push(key);
			}
		}

		// Check for keys that exist in afterObj but not in beforeObj.
		for (const key in afterObj) {
			if (afterObj.hasOwnProperty(key) && !beforeObj.hasOwnProperty(key)) {
				differentKeys.push(key);
			}
		}

		return differentKeys.length > 0 ? differentKeys : [];
	}
}
