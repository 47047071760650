import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {WebSocketEventInterface} from '../models/web-socket-event.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {NotifyUsers} from '../models/notify-users.interface';
import {catchError, mergeMap, Observable, retryWhen, throwError, timer} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	baseUrl: string = `${environment.url}/notification`;
	url: string = environment.webSocketUrl;
	retryAttempts: number = 1;

	public sub: WebSocketSubject<WebSocketEventInterface> = webSocket(this.url);

	constructor(private http: HttpClient, private authService: AuthService) {
		this.sub
			.pipe(
				retryWhen((errors) =>
					errors.pipe(
						mergeMap((error, index) => {
							console.log(`Reconnecting... (attempt ${this.retryAttempts})`);
							this.retryAttempts++;
							return timer(5000); // Retry after a delay of 5 seconds
						})
					)
				),
				catchError((error) => {
					console.log('Error occurred:', error);
					return throwError(error);
				})
			)
			.subscribe(
				(msg) => {
					if (msg.event === 'register-prompt') {
						this.sub.next({event: 'register', data: this.authService.userDataSource.value?.id!});
						console.log('WebSocket connected!');
						this.retryAttempts = 1;
					}
				}, // Called whenever there is a message from the server.
				(err) => console.log(err), // Called if at any point WebSocket API signals some kind of error.
				() => console.log('Websocket connection complete.') // Called when connection is closed (for whatever reason).
			);
	}

	markNotificationsAsRead(notifications: NotifyUsers[]): Observable<NotifyUsers[]> {
		return this.http.put<NotifyUsers[]>(this.baseUrl, notifications);
	}

	deleteNotification(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}

	deleteAllNotifications(): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/clear-all`);
	}
}
