<section class="p-4">
	<form
		class="border border-gray-300 rounded w-full m-auto flex justify-around items-baseline"
		[formGroup]="filterForm"
		(ngSubmit)="onSubmit()"
	>
		<h2>Filters</h2>

		<mat-checkbox color="primary" class="mb-4" [checked]="showOnlyChanges" (change)="onShowChangesCheckboxToggle()" [disabled]="!showTable"
			>Show Only Changes</mat-checkbox
		>

		<div class="w-1/4 mt-[22px] flex justify-start">
			<mat-form-field class="w-3/4">
				<mat-label>Table Name:</mat-label>
				<mat-select class="w-1/3" formControlName="tableName" (selectionChange)="clearSearchOptions()">
					<mat-option *ngIf="!tableNames.length"
						><mat-icon><mat-spinner [diameter]="20"></mat-spinner></mat-icon>Loading...</mat-option
					>
					<mat-option *ngFor="let name of tableNames" [value]="name.tableName">{{
						name.tableName | underscoreRemove | titlecase
					}}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-checkbox [checked]="showOnlyLoggedTables" (change)="onShowLoggedCheckboxToggle()" color="primary" class="mt-2 w-1/4"
				>Logging</mat-checkbox
			>
		</div>

		<mat-form-field class="w-1/4 mt-[22px]">
			<mat-label>Search Term</mat-label>
			<input matInput class="w-1/3" formControlName="searchValue" [matAutocomplete]="auto" (keyup)="selectedTablePk = ''" />
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onTablePkSelection($event.option.value)" [displayWith]="displayFn">
				<div class="flex flex-row w-full border-b-2 w-full px-4">
					<div class="w-1/6">ID</div>
					<div class="pl-4">Matches</div>
				</div>
				<mat-option class="audit-option" *ngFor="let option of autocompleteOptions" [value]="option">
					<div class="flex flex-row w-full">
						<div class="w-1/6">{{ option.tablePkId }}</div>
						<div class="flex flex-col pl-4">
							<li *ngFor="let match of option.matches">{{ match }}</li>
						</div>
					</div>
				</mat-option>
				<mat-option class="option-no-click" disabled *ngIf="autocompleteOptionsLoading"
					><mat-icon><mat-spinner [diameter]="18"></mat-spinner></mat-icon
				></mat-option>
				<mat-option
					class="option-no-click"
					disabled
					*ngIf="!autocompleteOptionsLoading && !autocompleteOptions.length && searchValue.length >= SEARCH_MIN_CHARACTERS"
				>
					No Results Found
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>

		<div class="flex justify-end">
			<button mat-flat-button color="primary" [disabled]="filterForm.invalid || loading || !selectedTablePk">Submit</button>
		</div>
	</form>

	<!--  Results table-->
	<section
		*ngIf="showTable && !loading"
		class="w-full max-h-[73vh] border border-black border-2 rounded h-full overflow-auto mx-auto mt-4 flex bg-gray-400"
	>
		<div class="sticky-left flex min-w-fit">
			<!-- keys column-->
			<app-audit-log-display [keys]="keys"></app-audit-log-display>
			<!-- 1st Object columns -->
			<app-audit-log-display [data]="displayColumns[0]" [displayAllData]="true" class="min-w-fit"></app-audit-log-display>
		</div>

		<!-- Object Columns -->
		<app-audit-log-display
			*ngFor="let column of displayColumns; index as i"
			[ngClass]="{hidden: i == 0}"
			[data]="column"
			class="min-w-fit"
		></app-audit-log-display>
	</section>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div>
</section>
